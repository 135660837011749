import { React, useState } from "react";
import Article from "./components/Article";

const About = () => {
  const [isArrow, setIsArrow] = useState(false);

  return (
    <section id="about">
      <h1>About me </h1>
      <span>My introduction</span>
      <div className="about-container">
        <img src="./images/profil3.jpg" alt="profil2" />

        <div className="about-container2">
          <div className="about-article-container">
            <Article
              i="fa fa-graduation-cap"
              h3="Experience"
              span="3+ Years working"
            />
            <Article i="fa fa-users" h3="Clients" span="200+ Wordwide" />
            <Article i="fa fa-folder-open" h3="Projects" span="80+ Completed" />
          </div>
          <p>
            I have very advanced skills in frontend and backend development and
            my clients are always satisfied with the quality work I produce.
          </p>
          <a
            className="btn"
            href="CV_Jean_Thierry_SITIO_NOUKA.pdf"
            style={{ backgroundColor: "#20232A" }}
            onMouseOver={() => {
              setIsArrow(true);
            }}
            onMouseOut={() => {
              setIsArrow(false);
            }}
          >
            Download CV &nbsp;&nbsp;
            <i
              className={
                isArrow ? "fa fa-download show-arrow" : "fa fa-download"
              }
            ></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
