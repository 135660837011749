import React from "react";
import { useState } from "react";

const Home = () => {
  const symbole = "</>";
  const [isArrow, setIsArrow] = useState(false);

  return (
    <section id="home">
      <div className="post-container">
        <div className="post-container2">
          <div className="post firstpost">
            <img src="./images/post1.jpg" alt="post1" className="post-img" />
            <div className="post-description">
              Computer and telecommunications networks
            </div>
          </div>
          <div className="post secondpost">
            <img src="./images/post2.jpg" alt="post1" className="post-img" />
            <div className="post-description">Database Administrator</div>
          </div>
          <div className="post thirdpost">
            <img src="./images/post3.jpg" alt="post1" className="post-img" />
            <div className="post-description">
              Computer security and cybersecurity
            </div>
          </div>
          <div className="post fourthpost">
            <img src="./images/post4.jpg" alt="post1" className="post-img" />
            <div className="post-description">Web & mobile development</div>
          </div>
        </div>
      </div>
      <div className="profil-container">
        <div className="profil-info-container">
          <h1 className="symbole">{symbole}</h1>
          <div className="profil-info">
            Hello i'm <br />
            <span className="profil-name">SITIO Thierry</span>
          </div>
        </div>
        <img
          src="./images/profil1.png"
          alt="profil1"
          className="profil1-image"
        />
        <div className="job-info-container">
          <div className="job-info">
            Fullstack Developper &nbsp; | &nbsp;&nbsp;&nbsp;
            <i className="fa fa-phone phone-info-icon"></i>{" "}
            <span className="info-number">+49 15777145328</span>
          </div>
          <br />
          <a
            className="btn"
            href="#contact"
            onMouseOver={() => {
              setIsArrow(true);
            }}
            onMouseOut={() => {
              setIsArrow(false);
            }}
          >
            Contact me &nbsp;&nbsp;
            <i
              className={
                isArrow
                  ? "fa fa-chevron-right show-arrow"
                  : "fa fa-chevron-right"
              }
            ></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
