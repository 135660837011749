import React from "react";
import { useState } from "react";

const Qualifications = () => {
  const [isNav, setisNav] = useState(true);
  const [isArrow, setIsArrow] = useState(false);
  const [isDown, setIsDown] = useState(false);

  const [educPos, setEducPos] = useState(0);
  const [workPos, setWorkPos] = useState(1);

  const educ_style = {
    borderBottom: isNav ? "1px solid #62DAFC" : "none",
    color: isNav ? "#62DAFC" : "white",
  };
  const work_style = {
    borderBottom: !isNav ? "1px solid #62DAFC" : "none",
    color: !isNav ? "#62DAFC" : "white",
  };

  return (
    <section id="qualification">
      <h1>Qualifications </h1>
      <span>My personal work</span>
      <div className="qualification-container">
        <div className="qualification-header">
          <div
            className="qualification-single-container"
            style={educ_style}
            onClick={() => {
              setisNav((prev) => !prev);
            }}
            onMouseOver
          >
            <i className="fa fa-graduation-cap"></i>&nbsp;&nbsp; Education
          </div>
          <div
            className="qualification-single-container"
            style={work_style}
            onClick={() => {
              setisNav((prev) => !prev);
            }}
          >
            <i class="fa-solid fa-laptop-code"></i>&nbsp;&nbsp; Work
          </div>
        </div>
        {isNav && (
          <div className="educ-container">
            <div className="qualification-post-title">
              {!(educPos > 0) && <i className="fa fa-circle"></i>}
              {educPos > 0 && <i className="fa fa-check-circle"></i>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <div>Computer Science student at the University Bremen</div>
            </div>
            <div className="qualification-post-info-container">
              {educPos == 0 && (
                <>
                  <div className="qualification-post-info-description">
                    I am currently expanding my expertise in computer science
                    studies at the University of Bremen. My goal is to
                    continuously sharpen my technical skills in order to develop
                    complex software solutions and leave a positive impact in
                    the tech industry..
                  </div>
                  <div className="qualification-post-info-date">
                    <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; October
                    2024 - now
                  </div>
                  <div className="qualification-post-info-buttons">
                    <a
                      onMouseOver={() => {
                        setIsArrow(true);
                      }}
                      onMouseOut={() => {
                        setIsArrow(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev + 1);
                        setIsArrow(false);
                      }}
                    >
                      NEXT &nbsp;&nbsp;
                      <i
                        className={
                          isArrow
                            ? "fa fa-chevron-down show-arrow"
                            : "fa fa-chevron-down"
                        }
                      ></i>
                    </a>
                  </div>
                </>
              )}
            </div>

            <div className="qualification-post-title">
              {!(educPos > 1) && <i className="fa fa-circle"></i>}
              {educPos > 1 && <i className="fa fa-check-circle"></i>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <div>
                Computer Engineer at ENSPD(Institut National Supérieur
                Polytechnique de Douala){" "}
              </div>
            </div>
            <div className="qualification-post-info-container">
              {educPos == 1 && (
                <>
                  <div className="qualification-post-info-description">
                    I studied for an engineering cycle in computer science at
                    the IME university in Cameroon.
                  </div>
                  <div className="qualification-post-info-date">
                    <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; October
                    2020 - June 2023
                  </div>
                  <div className="qualification-post-info-buttons">
                    <a
                      onMouseOver={() => {
                        setIsArrow(true);
                      }}
                      onMouseOut={() => {
                        setIsArrow(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev + 1);
                        setIsArrow(false);
                      }}
                    >
                      NEXT &nbsp;&nbsp;
                      <i
                        className={
                          isArrow
                            ? "fa fa-chevron-down show-arrow"
                            : "fa fa-chevron-down"
                        }
                      ></i>
                    </a>
                    <a
                      onMouseOver={() => {
                        setIsDown(true);
                      }}
                      onMouseOut={() => {
                        setIsDown(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev - 1);
                        setIsDown(false);
                      }}
                    >
                      BACK &nbsp;&nbsp;
                      <i
                        className={
                          isDown
                            ? "fa fa-chevron-up show-arrow"
                            : "fa fa-chevron-up"
                        }
                      ></i>
                    </a>
                  </div>
                </>
              )}
            </div>
            <div className="qualification-post-title">
              {!(educPos > 2) && <i className="fa fa-circle"></i>}
              {educPos > 2 && <i className="fa fa-check-circle"></i>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <div>Certification RESPONSIVE WEB DESIGN</div>
            </div>
            <div className="qualification-post-info-container">
              {educPos == 2 && (
                <>
                  <div className="qualification-post-info-description">
                    I got my certification on the freeCodeCamp platform
                  </div>
                  <div className="qualification-post-info-date">
                    <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; October
                    2022
                  </div>
                  <div className="qualification-post-info-buttons">
                    <a
                      onMouseOver={() => {
                        setIsArrow(true);
                      }}
                      onMouseOut={() => {
                        setIsArrow(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev + 1);
                        setIsArrow(false);
                      }}
                    >
                      NEXT &nbsp;&nbsp;
                      <i
                        className={
                          isArrow
                            ? "fa fa-chevron-down show-arrow"
                            : "fa fa-chevron-down"
                        }
                      ></i>
                    </a>
                    <a
                      onMouseOver={() => {
                        setIsDown(true);
                      }}
                      onMouseOut={() => {
                        setIsDown(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev - 1);
                        setIsDown(false);
                      }}
                    >
                      BACK &nbsp;&nbsp;
                      <i
                        className={
                          isDown
                            ? "fa fa-chevron-up show-arrow"
                            : "fa fa-chevron-up"
                        }
                      ></i>
                    </a>
                  </div>
                </>
              )}
            </div>
            <div className="qualification-post-title">
              {!(educPos > 3) && <i className="fa fa-circle"></i>}
              {educPos > 3 && <i className="fa fa-check-circle"></i>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <div>Certification JAVASCRIPT Data Structure and Algorithms</div>
            </div>
            <div className="qualification-post-info-container">
              {educPos == 3 && (
                <>
                  <div className="qualification-post-info-description">
                    I got my certification on the freeCodeCamp platform
                  </div>
                  <div className="qualification-post-info-date">
                    <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; November
                    2022
                  </div>
                  <div className="qualification-post-info-buttons">
                    <a
                      onMouseOver={() => {
                        setIsArrow(true);
                      }}
                      onMouseOut={() => {
                        setIsArrow(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev + 1);
                        setIsArrow(false);
                      }}
                    >
                      NEXT &nbsp;&nbsp;
                      <i
                        className={
                          isArrow
                            ? "fa fa-chevron-down show-arrow"
                            : "fa fa-chevron-down"
                        }
                      ></i>
                    </a>
                    <a
                      onMouseOver={() => {
                        setIsDown(true);
                      }}
                      onMouseOut={() => {
                        setIsDown(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev - 1);
                        setIsDown(false);
                      }}
                    >
                      BACK &nbsp;&nbsp;
                      <i
                        className={
                          isDown
                            ? "fa fa-chevron-up show-arrow"
                            : "fa fa-chevron-up"
                        }
                      ></i>
                    </a>
                  </div>
                </>
              )}
            </div>
            <div className="qualification-post-title">
              {!(educPos > 4) && <i className="fa fa-circle"></i>}
              {educPos > 4 && <i className="fa fa-check-circle"></i>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <div>Certification Front End Development Libraries</div>
            </div>
            <div className="qualification-post-info-container">
              {educPos == 4 && (
                <>
                  <div className="qualification-post-info-description">
                    I got my certification on the freeCodeCamp platform
                  </div>
                  <div className="qualification-post-info-date">
                    <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; December
                    2022
                  </div>
                  <div className="qualification-post-info-buttons">
                    <a
                      onMouseOver={() => {
                        setIsDown(true);
                      }}
                      onMouseOut={() => {
                        setIsDown(false);
                      }}
                      onClick={() => {
                        setEducPos((prev) => prev - 1);
                        setIsDown(false);
                      }}
                    >
                      BACK &nbsp;&nbsp;
                      <i
                        className={
                          isDown
                            ? "fa fa-chevron-up show-arrow"
                            : "fa fa-chevron-up"
                        }
                      ></i>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!isNav && (
          <div className="work-container">
            (
            <div className="educ-container">
              <div className="qualification-post-title">
                {!(workPos > 1) && <i className="fa fa-circle"></i>}
                {workPos > 1 && <i className="fa fa-check-circle"></i>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <div>Fullstack Developer</div>
              </div>
              <div className="qualification-post-info-container">
                {workPos == 1 && (
                  <>
                    <div className="qualification-post-info-description">
                      I have worked as fullstack engineer in the company Rpro
                      Associates
                    </div>
                    <div className="qualification-post-info-date">
                      <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; July
                      2020 - September 2022
                    </div>
                    <div className="qualification-post-info-buttons">
                      <a
                        onMouseOver={() => {
                          setIsArrow(true);
                        }}
                        onMouseOut={() => {
                          setIsArrow(false);
                        }}
                        onClick={() => {
                          setWorkPos((prev) => prev + 1);
                          setIsArrow(false);
                        }}
                      >
                        NEXT &nbsp;&nbsp;
                        <i
                          className={
                            isArrow
                              ? "fa fa-chevron-down show-arrow"
                              : "fa fa-chevron-down"
                          }
                        ></i>
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="qualification-post-title">
                {!(workPos > 2) && <i className="fa fa-circle"></i>}
                {workPos > 2 && <i className="fa fa-check-circle"></i>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <div>Frontend Developer and App tester</div>
              </div>
              <div className="qualification-post-info-container">
                {workPos == 2 && (
                  <>
                    <div className="qualification-post-info-description">
                      I have worked as fullstack engineer in the company
                      Camencorp
                    </div>
                    <div className="qualification-post-info-date">
                      <i className="fa fa-calendar-days"></i>&nbsp;&nbsp;
                      November 2022 - January 2023
                    </div>
                    <div className="qualification-post-info-buttons">
                      <a
                        onMouseOver={() => {
                          setIsArrow(true);
                        }}
                        onMouseOut={() => {
                          setIsArrow(false);
                        }}
                        onClick={() => {
                          setWorkPos((prev) => prev + 1);
                          setIsArrow(false);
                        }}
                      >
                        NEXT &nbsp;&nbsp;
                        <i
                          className={
                            isArrow
                              ? "fa fa-chevron-down show-arrow"
                              : "fa fa-chevron-down"
                          }
                        ></i>
                      </a>
                      <a
                        onMouseOver={() => {
                          setIsDown(true);
                        }}
                        onMouseOut={() => {
                          setIsDown(false);
                        }}
                        onClick={() => {
                          setWorkPos((prev) => prev - 1);
                          setIsDown(false);
                        }}
                      >
                        BACK &nbsp;&nbsp;
                        <i
                          className={
                            isDown
                              ? "fa fa-chevron-up show-arrow"
                              : "fa fa-chevron-up"
                          }
                        ></i>
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="qualification-post-title">
                {!(workPos > 3) && <i className="fa fa-circle"></i>}
                {workPos > 3 && <i className="fa fa-check-circle"></i>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <div>Trainee Developer </div>
              </div>
              <div className="qualification-post-info-container">
                {workPos == 3 && (
                  <>
                    <div className="qualification-post-info-description">
                      I worked as fullstack trainee engineer in the company
                      IpowerWorld.
                    </div>
                    <div className="qualification-post-info-date">
                      <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; 17
                      January 2023 - May 2023
                    </div>
                    <div className="qualification-post-info-buttons">
                      <a
                        onMouseOver={() => {
                          setIsArrow(true);
                        }}
                        onMouseOut={() => {
                          setIsArrow(false);
                        }}
                        onClick={() => {
                          setWorkPos((prev) => prev + 1);
                          setIsArrow(false);
                        }}
                      >
                        NEXT &nbsp;&nbsp;
                        <i
                          className={
                            isArrow
                              ? "fa fa-chevron-down show-arrow"
                              : "fa fa-chevron-down"
                          }
                        ></i>
                      </a>
                      <a
                        onMouseOver={() => {
                          setIsDown(true);
                        }}
                        onMouseOut={() => {
                          setIsDown(false);
                        }}
                        onClick={() => {
                          setWorkPos((prev) => prev - 1);
                          setIsDown(false);
                        }}
                      >
                        BACK &nbsp;&nbsp;
                        <i
                          className={
                            isDown
                              ? "fa fa-chevron-up show-arrow"
                              : "fa fa-chevron-up"
                          }
                        ></i>
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="qualification-post-title">
                {!(workPos > 4) && <i className="fa fa-circle"></i>}
                {workPos > 4 && <i className="fa fa-check-circle"></i>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <div>Fullstack Developer </div>
              </div>
              <div className="qualification-post-info-container">
                {workPos == 4 && (
                  <>
                    <div className="qualification-post-info-description">
                      I worked as fullstack engineer in the company IpowerWorld.
                    </div>
                    <div className="qualification-post-info-date">
                      <i className="fa fa-calendar-days"></i>&nbsp;&nbsp; 17
                      January 2023 - May 2023
                    </div>
                    <div className="qualification-post-info-buttons">
                      <a
                        onMouseOver={() => {
                          setIsDown(true);
                        }}
                        onMouseOut={() => {
                          setIsDown(false);
                        }}
                        onClick={() => {
                          setWorkPos((prev) => prev - 1);
                          setIsDown(false);
                        }}
                      >
                        BACK &nbsp;&nbsp;
                        <i
                          className={
                            isDown
                              ? "fa fa-chevron-up show-arrow"
                              : "fa fa-chevron-up"
                          }
                        ></i>
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
            )
          </div>
        )}
      </div>
    </section>
  );
};

export default Qualifications;
