import React from "react";

const Lien = () => {
  return (
    <div className="lien-container">
      <a
        href="https://www.linkedin.com/in/jean-thierry-sitio-nouka-0193b2273/"
        target="_blank"
        rel="noreferrer"
        title="Linkedin"
      >
        <i className="fa-brands fa-linkedin-in social-link"></i>
      </a>
      <a
        href="mailto:thierrysitio@gmail.com"
        target="_blank"
        rel="noreferrer"
        title="Gmail"
      >
        <i className="fa fa-envelope social-link"></i>
      </a>
      <a
        href="https://github.com/STcodes"
        target="_blank"
        rel="noreferrer"
        title="Github"
      >
        <i className="fa-brands fa-github social-link"></i>
      </a>
      <a
        href="https://www.instagram.com/sitio_thierry/"
        target="_blank"
        rel="noreferrer"
        title="Instagram"
      >
        <i className="fa-brands fa-instagram social-link"></i>
      </a>
    </div>
  );
};

export default Lien;
