import { React, useState } from "react";
import Contact_info from "./components/Contact_info";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [isArrow, setIsArrow] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const contactData = [
    {
      icon: "fa fa-phone",
      title: "Call me",
      value: "+49 15777145328",
    },
    {
      icon: "fa fa-envelope",
      title: "Email",
      value: "thierrysitio@gmail.com",
    },
    {
      icon: "fa fa-location-dot",
      title: "Localisation",
      value: "Bremen - Germany",
    },
  ];
  function handleChange(event) {
    const { name, value } = event.target;

    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    emailjs
      .send(
        "service_cy09ua5",
        "template_y9gerbs",
        formData,
        "gJA3jcTBSZ9feQvJ5"
      )
      .then(
        function (response) {
          setIsSent(true);
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  }

  return (
    <section id="contact">
      <h1>Contact </h1>
      <span>Get in Touch</span>
      <div className="contact-container">
        <div className="contact-info-container">
          {contactData.map((item) => {
            return <Contact_info {...item} />;
          })}
        </div>
        <form action="" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your name..."
            required
            onChange={handleChange}
            autoComplete="false"
          />
          <input
            type="mail"
            name="email"
            placeholder="Your email address..."
            required
            onChange={handleChange}
            autoComplete="false"
          />
          <textarea
            name="message"
            placeholder="Your message..."
            required
            onChange={handleChange}
            autoComplete="false"
          ></textarea>
          <button
            className="contact-btn"
            href="#contact"
            onMouseOver={() => {
              setIsArrow(true);
            }}
            onMouseOut={() => {
              setIsArrow(false);
            }}
            type="submit"
          >
            {isSent ? "Message sent" : "Send Message"} &nbsp;&nbsp;
            <i
              className={
                isArrow
                  ? "fa fa-chevron-right show-arrow"
                  : "fa fa-chevron-right"
              }
            ></i>
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
