import React from "react";
import Portfolio_post from "./components/Portfolio_post";

const Portfolio = () => {
  const data = [
    {
      title: "L'OR PRIMMER",
      imageUrl: "./images/lor.png",
      description:
        "Online sales site for a multitude of products from the company L'OR PRIMMER based in France",
      demoUrl: "https://lorprimmer.camencorp.com",
      githubUrl: "https://github.com/STcodes",
    },
    {
      title: "CHAT APP",
      imageUrl: "./images/chat.png",
      description: "Telegram themed messaging app where you can talk easily",
      demoUrl: "https://chatapp.camencorp.com",
      githubUrl: "https://github.com/STcodes/chatapp",
    },
    {
      title: "CAMENCORP",
      imageUrl: "./images/camen.png",
      description:
        "Showcase website describing the services and exposing all the information of the Camencorp company",
      demoUrl: "https://camencorp.com",
      githubUrl: "https://github.com/STcodes",
    },
    {
      title: "CHANTIER237",
      imageUrl: "./images/chantier237.png",
      description:
        "Chantier237 is a mobile application that offers you the possibility in one click to find a job according to your convenience, preferences and conditions.",
      demoUrl:
        "https://play.google.com/store/apps/details?id=com.st.code.Chantier237",
      githubUrl: "https://github.com/STcodes/Chantier237",
    },
    {
      title: "PORTFOLIO STCODE",
      imageUrl: "./images/logo.png",
      description:
        "Portfolio of the fullstack developer Jean Thierry SITIO NOUKA",
      demoUrl: "https://stcode.camencorp.com",
      githubUrl: "https://github.com/STcodes/Portfolio",
    },
  ];

  return (
    <section id="portfolio">
      <h1>Portfolio </h1>
      <span>My recent work</span>
      <div className="portfolio-container">
        {data.map((item) => {
          return <Portfolio_post data={item} />;
        })}
      </div>
    </section>
  );
};

export default Portfolio;
