import React from "react";
import { useState } from "react";
import SkillProgress from "./components/SkillProgress";

const Skills = () => {
  const [view, setView] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 1182) {
      setView(true);
    }
  });

  return (
    <section id="skills">
      <h1>Skills</h1>
      <span className="skills-mess">My technical level</span>
      <div className="skills-container">
        {view && (
          <>
            <div className="skills-post">
              <h3>
                <i class="fa-solid fa-object-ungroup"></i>&nbsp;&nbsp; Frontend
                Developement
              </h3>
              <div className="skills-technology-container">
                <SkillProgress title="HTML" value={95} />
                <SkillProgress title="CSS" value={83} />
                <SkillProgress title="TAILWIND CSS" value={80} />
                <SkillProgress title="JAVASCRIPT" value={90} />
                <SkillProgress title="REACT JS" value={80} />
                <SkillProgress title="REACT NATIVE" value={80} />
                <SkillProgress title="MOTION" value={70} />
                <SkillProgress title="BOOTSTRAP" value={90} />
                <SkillProgress title="JQUERY" value={87} />
              </div>
            </div>
            <div className="skills-post">
              <h3>
                <i class="fa fa-server"></i>&nbsp;&nbsp; Backend Developement
              </h3>
              <div className="skills-technology-container">
                <SkillProgress title="PHP" value={97} />
                <SkillProgress title="JAVA" value={80} />
                <SkillProgress title="JUNIT" value={70} />
                <SkillProgress title="SQL" value={90} />
                <SkillProgress title="LARAVEL" value={50} />
                <SkillProgress title="ASP DOC. NET" value={60} />
                <SkillProgress title="NODE JS" value={70} />
                <SkillProgress title="NEXT JS" value={77} />
                <SkillProgress title="API REST" value={90} />
                <SkillProgress title="WORDPRESS" value={95} />
              </div>
            </div>
            <div className="skills-post">
              <h3>
                <i class="fa-solid fa-swatchbook"></i>&nbsp;&nbsp; Designer
              </h3>

              <div className="skills-technology-container">
                <SkillProgress title="FIGMA" value={80} />
                <SkillProgress title="ADOBE XD" value={70} />
                <SkillProgress title="PHOTOSHOP" value={60} />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Skills;
